<template>
  <b-card class="card-custom rounded-top-0" body-class="pt-15">
    <template v-slot:footer>
      <b-container class="max-w-900px text-right">
        <b-btn v-cs-loading="submiting" variant="primary" class="px-5" @click="onSaveData()">
          Save
        </b-btn>
      </b-container>
    </template>
    <b-container class="max-w-900px">
      <!--begin::Form Group-->
      <b-form-group>
        <template v-slot:label>
          Company's Name
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="text"
          class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          v-model="form.company_name"
          :state="validateState($v.form.company_name)"
        />
      </b-form-group>
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              First Name
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.first_name"
              :state="validateState($v.form.first_name)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Last Name
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.last_name"
              :state="validateState($v.form.last_name)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!--end::Form Group-->

      <h3 class="text-dark text-center my-12">Contact Information</h3>

      <!--begin::Form Group-->
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              E-mail
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="userData.email"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Phone
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.phone"
              :state="validateState($v.form.phone)"
              v-mask="defaultMask.phone"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="8">
          <b-form-group>
            <template v-slot:label>
              Street Address
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.street_address"
              :state="validateState($v.form.street_address)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              Apt/Suite
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.suite"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              City
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.city"
              @keypress="onlyLetter($event)"
              :state="validateState($v.form.city)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              State
              <span class="text-danger">*</span>
            </template>
            <b-form-select
              :options="stateOptions"
              class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.state"
              :state="validateState($v.form.state)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              Zip
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-mask="defaultMask.zip"
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="form.zip"
              :state="validateState($v.form.zip)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!--end::Form Group-->

      <h3 class="text-dark text-center my-12">Social Details</h3>

      <!--begin::Form Group-->
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Social Security Number
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-mask="defaultMask.social_security_number"
              v-model="form.social_security_number"
              :state="validateState($v.form.social_security_number)"
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group :state="validateState($v.form.birth_date)">
            <template v-slot:label>
              Date of Birth
              <span class="text-danger">*</span>
            </template>
            <date-picker v-model="form.birth_date"></date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <!--end::Form Group-->
    </b-container>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength, integer, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'profile-personal-information',
  validations: {
    form: {
      company_name: { required },
      first_name: { required },
      last_name: { required },
      street_address: { required },
      city: { required },
      state: { required },
      phone: { required, minLength: minLength(13) },
      zip: { required, integer, minLength: minLength(5), maxLength: maxLength(5) },
      social_security_number: { required, minLength: minLength(11) },
      birth_date: { required },
    },
  },
  data() {
    return {
      submiting: false,
      form: {},
    };
  },
  computed: {
    userData() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    const name = [this.userData.first_name || '', this.userData.last_name || ''].filter((e) => e != '');
    this.$root.$emit('changeTitle', name.join(' ') || this.userData.email);
    this.initForm(this.userData);
  },
  methods: {
    ...mapActions({
      updateProfile: 'auth/updateProfile',
    }),
    initForm(data = {}) {
      this.form = {
        company_name: data.company_name || '',
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        street_address: data.street_address || '',
        phone: data.phone || '',
        city: data.city || '',
        suite: data.suite || '',
        state: data.state || '',
        zip: data.zip || '',
        social_security_number: data.social_security_number || '',
        birth_date: data.birth_date || '',
      };
    },
    onSaveData() {
      if (!this.$v.form.$invalid) {
        this.submiting = true;
        this.updateProfile(this.form).finally(() => (this.submiting = false));
      } else {
        this.$v.$touch();
      }
    },
  },
};
</script>
